import 'reflect-metadata';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import moment from 'moment';
import packageJson from '../package.json';
import * as Sentry from '@sentry/angular';

// Send Date with timezone
// eslint-disable-next-line no-extend-native
Date.prototype.toJSON = function () {
  return moment(this).format();
};

Date.prototype.toISOString = function () {
  return moment(this).format();
};

if (environment.production) {
  enableProdMode();
}

if (environment.sentryDsn) {
  Sentry.init({
    dsn: 'https://c10fc6c8392818179e8aadb16162f676@o4507021723303936.ingest.us.sentry.io/4507491640934401',
    release: packageJson.version,
    environment: environment.environmentName,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/portal\.altorney\.com/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

(window as any).altorney = {
  build: () => ({ version: packageJson.version }),
};

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
